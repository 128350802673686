import IndexPage from '../components/landing/IndexPage'
import {getIndexStaticProps} from '../components/landing/utils'
import DefaultLayout from '../components/layouts/DefaultLayout'

export default function Index({
  field,
  indexPage,
  resources,
  featuredAuthors,
  featuredJobs,
}) {
  return (
    <IndexPage
      field={field}
      indexPage={indexPage}
      resources={resources}
      featuredAuthors={featuredAuthors}
      featuredJobs={featuredJobs}
    />
  )
}

Index.getLayout = (page, pageProps) => (
  <DefaultLayout pageType="index" field={pageProps.field}>
    {page}
  </DefaultLayout>
)

export const getStaticProps = () => getIndexStaticProps('Index')
